<template>
  <div class="zt-page-content">
    <div class="page-header">运单管理</div>
    <div class="zt-block tab">
      <el-form ref="search" :inline="true" :label-width="'100px'">
        <el-row style="padding-top: 15px">
          <el-form-item label="运单编号：" size="mini">
            <el-input
              v-model="search.waybillCode"
              clearable
              size="mini"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否分配：" size="mini">
            <el-select
              @change="selectAll"
              v-model="search.IsDistribution"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                placeholder="请选择"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="end">
          <el-form-item label size="mini">
            <el-button size="mini" @click="onAssign" type="primary"
              >自动派单</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="onSearch"
              :loading="loading"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button size="mini" @click="onReset" icon="el-icon-refresh-left"
              >重置</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="zt-block" style="height: calc(100% - 175px)">
      <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
        <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%"
          @select-all="onSelectRowAll"
          @select="onSelectRow"
          :empty-text="'无数据'"
        >
          <el-table-column
            align="center"
            width="60"
            type="selection"
          ></el-table-column>
          <el-table-column prop="waybillCode" label="运单编号">
            <template slot-scope="scope">
              <!-- <div>{{ scope.row.apply_at | moment("YYYY/MM/DD") }}</div> -->
              <div>{{ scope.row.waybillCode }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="waybillSource" label="运单来源">
            <template slot-scope="scope">
              <div>{{ scope.row.waybillSource | filter(dictionaries) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="waybillStatus" label="运单状态">
            <template slot-scope="scope">
              <div>
                {{ scope.row.waybillStatus | filterStatus(waybillStatusList) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="comName" label="物流公司">
            <template slot-scope="scope">
              <div>{{ scope.row.comName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="warehouseName" label="仓库名称">
            <template slot-scope="scope">
              <div>{{ scope.row.warehouseName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" label="收件公司名称">
            <template slot-scope="scope">
              <div>{{ scope.row.customerName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerAddress" label="收件联系地址">
            <template slot-scope="scope">
              <div>{{ scope.row.customerAddress }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerContacts" label="收件联系人">
            <template slot-scope="scope">
              <div>{{ scope.row.customerContacts }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="customerContactsPhone" label="收件联系电话">
            <template slot-scope="scope">
              <div>{{ scope.row.customerContactsPhone }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="createdBy" label="创建人">
            <template slot-scope="scope">
              <div>{{ scope.row.createdBy }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="meter_number" label="操作">
            <template slot-scope="scope">
              <el-button
                :disabled="
                  scope.row.isDistribution != 0 || scope.row.isPublic != 0
                "
                type="text"
                @click="distribute(scope.row)"
                >分配</el-button
              >
              <el-button
                type="text"
                style="color: #ff9422"
                @click="onDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 12px">
        <el-pagination
          background
          @current-change="onPageChange"
          @size-change="onPageSizeChange"
          :current-page="search.pageIndex"
          :page-size="search.pageSize"
          layout="prev, pager, next"
          :total="search.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="运单分配"
      :visible.sync="visible"
      ref="ruleForm"
      width="500px"
    >
      <el-form :model="form" :rules="rules">
        <el-form-item label="物流公司" prop="name">
          <el-select
            v-model="form.orgId"
            placeholder="请选择物流公司"
            style="width: 350px"
          >
            <el-option
              v-for="(item, i) in companys"
              :key="i"
              :label="item.enterpriseName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 20px">
        <el-button @click="dialogClose" size="small btn_style3">取消</el-button>
        <el-button @click="submit" size="small btn_style1">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-loading="loading"
      id="dialog"
      title="运单详情"
      :visible.sync="detailVisible"
      width="1500px"
    >
      <div class="xqtitle">运单信息</div>
      <el-form :model="detail.waybill" :inline="true" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="运单编号：" prop="waybillCode">
              <el-input
                disabled
                v-model="detail.waybill.waybillCode"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="运单来源：" prop="waybillSource">
              <el-input
                disabled
                v-model="detail.waybill.waybillSource"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="运单状态：" prop="waybillStatus">
              <el-input
                disabled
                v-model="detail.waybill.waybillStatus"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品总件数：" prop="totalCount">
              <el-input
                disabled
                v-model="detail.waybill.totalCount"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库编号：" prop="warehouseCode">
              <el-input
                disabled
                v-model="detail.waybill.warehouseCode"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库名称：" prop="warehouseName">
              <el-input
                disabled
                v-model="detail.waybill.warehouseName"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="安装方式：" prop="installationMethod">
              <el-input
                disabled
                v-model="detail.waybill.installationMethod"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="寄件方式：" prop="mailingMethod">
              <el-input
                disabled
                v-model="detail.waybill.mailingMethod"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="调度方案截止日期："
              prop="dispatchLastDataTime"
            >
              <el-input
                disabled
                v-model="detail.waybill.dispatchLastDataTime"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注：" prop="remark">
              <el-input
                type="textarea"
                disabled
                v-model="detail.waybill.remark"
                clearable
                :maxlength="200"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="xqtitle">收件信息</div>
      <el-form :model="detail.waybill" :inline="true" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="收件公司名称：" prop="customerName">
              <el-input
                disabled
                v-model="detail.waybill.customerName"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收件人：" prop="customerContacts">
              <el-input
                disabled
                v-model="detail.waybill.customerContacts"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收件人电话：" prop="customerContactsPhone">
              <el-input
                disabled
                v-model="detail.waybill.customerContactsPhone"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收件地址：" prop="customerAddress">
              <el-input
                disabled
                v-model="detail.waybill.customerAddress"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="xqtitle">寄件信息</div>
      <el-form :model="detail.waybill" :inline="true" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="寄件公司名称：" prop="enterpriseName">
              <el-input
                disabled
                v-model="detail.waybill.enterpriseName"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="寄件人：" prop="enterpriseDirector">
              <el-input
                disabled
                v-model="detail.waybill.enterpriseDirector"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="寄件人电话：" prop="enterpriseDirectorMobile">
              <el-input
                disabled
                v-model="detail.waybill.enterpriseDirectorMobile"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="寄件地址：" prop="enterpriseAdress">
              <el-input
                disabled
                v-model="detail.waybill.enterpriseAdress"
                clearable
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="xqtitle">运单明细表</div>
      <el-table
        border
        :data="detail.details"
        :header-cell-style="{ backgroundColor: '#f5f7fa' }"
      >
        <el-table-column prop="goodsCode" label="物品编号"></el-table-column>
        <el-table-column prop="goodsName" label="物品名称"></el-table-column>
        <el-table-column prop="specifications" label="规格"></el-table-column>
        <!-- <el-table-column label="物品图片">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.goodsPicture"
                  :src="scope.row.goodsPicture"
                  style="max-height: 100px; max-width: 100px"
                />
                <span v-else>暂无图片</span>
              </template>
            </el-table-column> -->
        <el-table-column prop="unit" label="单位"></el-table-column>
        <el-table-column label="型号" prop="goodsType">
          <!-- <template slot-scope="scope">
                <DictionariesView
                  :value="scope.row.goodsType"
                  dictCode="GoodsType"
                />
              </template> -->
        </el-table-column>
        <el-table-column prop="color" label="颜色"></el-table-column>
        <el-table-column prop="size" label="尺寸"></el-table-column>
        <el-table-column prop="number" label="数量"></el-table-column>
        <!-- <el-table-column
              prop="unitPriceOfFreight"
              label="运费单价"
            ></el-table-column>
            <el-table-column
              prop="unitPriceOfLoadingFee"
              label="装车费单价"
            ></el-table-column> -->
      </el-table>
      <div style="text-align: center; margin-top: 20px">
        <el-button @click="detailVisible = false" size="small btn_style3"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { WLBASE_URL } from "@/config";
export default {
  components: {},
  filters: {
    // <div v-if="scope.row.waybillStatus == 1">运单创建</div>
    //           <div v-if="scope.row.waybillStatus == 2">确认调度方案</div>
    //           <div v-if="scope.row.waybillStatus == 3">运单收货</div>
    //           <div v-if="scope.row.waybillStatus == 4">运输中</div>
    //           <div v-if="scope.row.waybillStatus == 5">运单到达</div>
    filter(val, dictionaries) {
      return dictionaries.filter((item) => val == item.value)[0].name;
    },
    filterStatus(val, waybillStatusList) {
      return waybillStatusList.filter((item) => val == item.value)[0].label;
    },
  },
  data() {
    return {
      installationMethodList: [
        { label: "自己安装", value: 0 },
        { label: "预约安装", value: 1 },
      ], //安装方式  0 自己安装 1预约安装
      mailingMethodList: [
        { label: "自己送货", value: 2 },
        { label: "上门提货", value: 1 },
      ], //寄件方式   1上门提货  0自己送货
      waybillStatusList: [
        { label: "运单创建", value: 1 },
        { label: "确认调度方案", value: 2 },
        { label: "运单收货", value: 3 },
        { label: "运输中", value: 4 },
        { label: "运单到达", value: 5 },
      ],
      loading: false,
      selectList: [],
      search: {
        waybillCode: "", //运单编号
        IsDistribution: -1,
        orgId: undefined,
        tenantId: 0,
        pageIndex: 1,
        pageSize: 10,
        total: 0, //总数
        noCount: false,
      },
      options: [
        { label: "全部", value: -1 },
        { label: "已分配", value: 1 },
        { label: "未分配", value: 0 },
      ],
      waybillCode: "",
      form: {
        orgId: undefined,
        id: "",
        waybillCode: "",
      },
      total: 0,
      dictionaries: [], //运单来源字典
      list: [],
      rules: {
        orgId: [
          { required: true, message: "请选择物流公司", trigger: "change" },
        ],
      },
      ruleForm: "",
      itemId: null,
      details: [],
      visible: false,
      detail: {
        waybill: {
          waybillCode: "",
        },
        details: {},
        trails: {},
      },
      detailVisible: false,
      companys: [],
    };
  },
  async created() {
    this.getDictionaries();
    this.getList();
    this.getAllComp();
  },
  methods: {
    //分配
    distribute(row) {
      this.form.id = row.id;
      // this.form.waybillCode = row.waybillCode;
      this.visible = true;
    },
    onAssign() {
      if (this.selectList.length != 0) {
        let every = this.selectList.every((item) => {
          return item.isDistribution != 0;
        });
        console.log(every, "every");
        if (every) {
          this.$message.error("存在已分配的运单");
        } else {
          let url = WLBASE_URL + "/api/PassTokenWaybill/ReleasePublic";
          let data = {
            idList: [],
          };
          data.idList = this.selectList.map((item) => {
            return item.id;
          });
          this.axios({
            url,
            method: "POST",
            data,
          }).then((res) => {
            if (res.data.success) {
              this.getList();
              this.$message.success("自动派单成功");
            } else {
              this.$message.error("自动派单失败");
            }
          });
        }
      } else {
        this.$message.error("请勾选运单条目");
      }
    },
    onSelectRow(e) {
      this.selectList = e;
    },
    onSelectRowAll(e) {
      this.selectList = e;
    },
    submit() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/ReleaseDistribution";
      let data = {
        // waybillCode: this.form.id,
        waybillId: this.form.id,
        isDistribution: this.form.orgId, //分配给谁
        orgId: this.search.orgId,
        tenantId: 0,
      };
      this.axios({
        url,
        method: "POST",
        data,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("分配成功");
          this.visible = false;
          this.getList();
        } else {
          this.$message.error("分配失败");
        }
      });
    },
    dialogClose() {
      if (this.visible) {
        this.visible = false;
        this.form.orgId = undefined;
        this.form.id = "";
      }
    },
    getAllComp() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/PageAccountType";
      let data = {
        orgType: 7,
      };
      this.axios({
        url,
        method: "POST",
        data,
      }).then((res) => {
        if (res.data.success) {
          this.companys = res.data.content.list;
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    onReset() {
      this.search = {
        waybillCode: "", //运单编号
        IsDistribution: -1,
        orgId: this.search.orgId,
        tenantId: 0,
        pageIndex: 1,
        pageSize: 10,
        total: 0, //总数
        noCount: false,
      };
      console.log(this.search.orgId, "this.search.orgId");
      this.onSearch();
    },
    onDetail(row) {
      this.loading = true;
      this.detailVisible = true;
      let url = WLBASE_URL + "/api/PassTokenWaybill/GetSendDetail";
      let data = {
        id: row.id,
        orgId: this.search.orgId,
        tenantId: 0,
      };
      this.axios({
        url,
        method: "POST",
        data,
      }).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.detail = res.data.content;
          this.detail.waybill.waybillSource = this.dictionaries.filter(
            (item) => item.value == this.detail.waybill.waybillSource
          )[0].name;
          this.detail.waybill.waybillStatus = this.waybillStatusList.filter(
            (item) => item.value == this.detail.waybill.waybillStatus
          )[0].label;
          this.detail.waybill.installationMethod =
            this.installationMethodList.filter(
              (item) => item.value == this.detail.waybill.installationMethod
            )[0].label;
          this.detail.waybill.mailingMethod = this.mailingMethodList.filter(
            (item) => item.value == this.detail.waybill.mailingMethod
          )[0].label;
          this.detail.waybill.dispatchLastDataTime =
            this.detail.waybill.dispatchLastDataTime.replace("T", " ");
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    getDictionaries() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/PagingDictionaries";
      let data = {
        code: "WaybillSource",
        orgId: this.search.orgId,
        pageIndex: 1,
        pageSize: 100,
        tenantId: 0,
      };
      this.axios({
        url,
        method: "POST",
        data,
      }).then((res) => {
        if (res.data.success) {
          this.dictionaries = res.data.content.datas;
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    selectAll() {
      this.onSearch();
    },
    onSearch() {
      this.getList(1);
    },
    onPageChange: function (pageIndex) {
      this.search.pageIndex = pageIndex;
      this.getList(pageIndex);
    },
    onPageSizeChange: function (pageSize) {
      this.search.pageIndex = 1;
      this.search.pageSize = pageSize;
      this.getList();
    },
    goto(link) {
      this.$router.push(link);
    },
    getList() {
      console.log("测试");
      this.loading = true;
      if (WLBASE_URL == "https://consul.mes.emergen.cn:6012") {
        this.search.orgId = 2754;
      } else if (WLBASE_URL == "https://consul.mes.emergen.cn:5004") {
        this.search.orgId = 2959;
      } else if (WLBASE_URL == "https://blcapi.nk001.com") {
        this.search.orgId = 2139;
      }
      let url = WLBASE_URL + "/api/PassTokenWaybill/PagingTMSWayBillISDal";
      this.axios({
        url,
        method: "POST",
        data: this.search,
      }).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.search.total = res.data.content.result.recordCount;
          this.list = res.data.content.result.datas;
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.tab {
  padding: 0 20px;
}
.tab_menu .menu_item {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  margin-right: 50px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur {
  color: #4d4d4d;
  border-bottom: 2px solid #4d4d4d;
}

div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__body {
  /* background: #f5f5f5; */
  background: #fff;
}
.xqtitle {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  line-height: 24px;
  padding: 0 2px;
  display: inline-block;
  margin-bottom: 20px;
}
.xqtitle::after {
  position: absolute;
  bottom: -4px;
  height: 4px;
  width: 100%;
  background: #5074ee;
  content: "";
  left: 0;
}
#dialog >>> .el-input__inner {
  width: 260px;
  height: 40px;
}
#dialog >>> .el-textarea__inner {
  width: 1235px;
  height: 100px;
}
#dialog >>> .el-table {
  background: #f5f7fa;
}
</style>
